<template>
  <a-spin :spinning="btnLoadding || spinning">
    <div class="my-task" :style="backCss">
      <div class="left" v-if="taskList && taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          @click="prev"
          :style="isDesktop() ? 'font-size:30px' : 'font-size:15px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId)"
          type="left-circle"
          theme="filled"
        />
        <span
          :style="isDesktop() ? 'font-size:25px' : 'font-size:12px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId)"
          >{{ taskDetail.chapterSequenceNo - 1 }}</span
        >
      </div>
      <div class="right" v-if="taskList && taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          @click="next"
          :style="isDesktop() ? 'font-size:30px' : 'font-size:15px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId) != taskList.length - 1"
          type="right-circle"
          theme="filled"
        />
        <span :style="isDesktop() ? 'font-size:25px' : 'font-size:12px'">{{
          taskDetail.chapterSequenceNo - 0 + 1
        }}</span>
      </div>
      <a-row>
        <a-breadcrumb separator=">">
          <a-breadcrumb-item style="font-size:16px">
            <span style="cursor: pointer;" @click="skip">我的任务</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item style="font-size:16px">
            <span style="cursor: pointer;">上传原稿</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item href="" style="font-size:16px">
            {{
              taskDetail.chapterSequenceNo
                ? taskDetail.showName + ' - ' + taskDetail.chapterSequenceNo + '-' + taskDetail.stageName
                : taskDetail.showName + '-' + taskDetail.stageName
            }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </a-row>
      <a-row class="content">
        <a-col :span="12">
          <div class="left-button-list">
            <a-button-group style="display:flex">
              <a-button
                icon="unordered-list"
                @click="changeViewType('list')"
                :style="viewType == 'list' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'list' ? 'primary' : ''"
                :ghost="viewType == 'list' ? true : false"
              ></a-button>
              <a-button
                @click="changeViewType('widthView')"
                :style="viewType == 'widthView' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'widthView' ? 'primary' : ''"
                :ghost="viewType == 'widthView' ? true : false"
              >
                <span
                  class="iconfont icon-dengkuanfenbu icon"
                  :style="viewType == 'widthView' ? `color:${this.color} !important` : 'color:black !important;'"
                ></span>
              </a-button>
              <a-button
                @click="changeViewType('heightView')"
                :style="viewType == 'heightView' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'heightView' ? 'primary' : ''"
                :ghost="viewType == 'heightView' ? true : false"
              >
                <span
                  class="iconfont icon-denggaofenbu icon"
                  :style="viewType == 'heightView' ? `color:${this.color} !important` : 'color:black !important;'"
                ></span>
              </a-button>
            </a-button-group>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="right-button-list">
            <a-button @click="joint" :loading="btnLoadding" icon="database" class="mr-3">
              拼接
            </a-button>
            <a-dropdown-button
              v-if="taskDetail.isMachineTranslation"
              @click="btnLoadding ? () => {} : translate(0)"
              class="mr-3"
              :trigger="['click']"
            >
              <a-menu slot="overlay">
                <a-menu-item key="1" @click="translate(1)"> 切换机翻 </a-menu-item>
              </a-menu>
              <span>
                <a-icon type="diff" />
                <!--                 <a-spin v-else>
                  <a-icon slot="indicator" type="loading" spin />
                </a-spin> -->
                机翻
              </span>
              <a-icon @click.stop v-if="taskDetail.isMachineTranslation" type="down" slot="icon" />
            </a-dropdown-button>
            <!--             <a-button @click="ocr" :loading="btnLoadding" icon="scan" class="mr-3" type="primary">
              OCR
            </a-button> -->
            <a-dropdown-button
              @click="btnLoadding ? () => {} : ocr()"
              type="primary"
              :spinning="btnLoadding"
              class="mr-3"
              :trigger="['click']"
            >
              <a-menu slot="overlay">
                <!-- <a-menu-item @click="ocr" key="1">识别文本框</a-menu-item> -->
                <a-menu-item @click="noOcr" key="2">清除结果</a-menu-item>
              </a-menu>
              <span @click="ocr">
                <a-icon type="scan" />
                <!--                 <a-spin v-else>
                  <a-icon slot="indicator" type="loading" spin />
                </a-spin> -->
                OCR
              </span>
              <a-icon type="down" @click.stop slot="icon" />
            </a-dropdown-button>
            <a-button
              class="mr-3"
              @click="goToTranslate"
              v-if="
                (taskDetail.stageName == '翻译' ||
                  taskDetail.stageName == '润色' ||
                  taskDetail.stageName == '校对' ||
                  taskDetail.stageName == '制作' ||
                  taskDetail.stageName == '监制' ||
                  taskDetail.stageName == '终审' ||
                  taskDetail.stageName == '给稿') &&
                  taskDetail.applyTranslation
              "
            >
              <span class="iconfont icon-fanyi icon" style="color:rgba(0,0,0,0.65);font-size:18px"></span>
            </a-button>
            <!--             <a-button type="primary" class="mr-3">
              <span class="iconfont icon-duohangwenben icon"></span>
              读取
            </a-button> -->
            <actions-tasks-btn
              :propsParams="taskDetail"
              :loadData="reloadData"
              :taskList="taskList"
            ></actions-tasks-btn>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" class="left-content">
          <div class="left-content-button-list">
            <!--             <a-upload
              name="file"
              :showUploadList="false"
              :multiple="true"
              accept=".psd,.jpg"
              :action="ossUploadUrl"
              :data="ossUploadData"
              :before-upload="beforeLeftUpload"
              :custom-request="
                options => {
                  handleUpload(options, 'PSD', 'original')
                }
              "
              @change="
                info => {
                  handleChangeUpload(info, 'PSD')
                }
              "
            >
              <a-button icon="upload" class="mr-3" type="primary">
                上传原稿
              </a-button>
            </a-upload> -->
            <defaultSizeUpload
              ref="defaultSizeUpload"
              btnText="上传原稿"
              upType="PSD"
              :taskDetail="taskDetail"
              @startProgress="startProgress"
              @changeProgress="changeProgress"
              @changeTransProgress="changeTransProgress"
              @endProgress="endProgress"
              btnType="original"
              :sizeList="sizeList"
              :loadData="getLeftTableData"
              :showFileList="leftDataSource"
              saveUrl="/kt/translate/uploadOriginalManuscript"
            />
            <defaultSizeReplace
              btnText="替换"
              upType="PSD"
              :taskDetail="taskDetail"
              @startProgress="startProgress"
              @changeProgress="changeProgress"
              @changeTransProgress="changeTransProgress"
              @endProgress="endProgress"
              btnType="original"
              :sizeList="sizeList"
              :loadData="getLeftTableData"
              :showFileList="leftDataSource"
              :selectKeys="selectedPsdRowKeys"
              saveUrl="/original/manuscript/replace"
            />
            <!--             <a-button
              :disabled="!selectedPsdRowKeys.length || selectedPsdRowKeys.length > 1"
              @click="replacePsdImg"
              :loading="btnLoading"
              class="mr-3"
            >
              替换
            </a-button> -->
            <a-button
              :disabled="!selectedPsdRowKeys.length"
              @click="deletePsdImg"
              :loading="btnLoading"
              icon="delete"
              class="mr-3"
              type="danger"
            >
              删除
            </a-button>
          </div>
          <div class="left-content-list">
            <a-table
              v-if="viewType == 'list' && !leftShow"
              size="middle"
              rowKey="id"
              :columns="columns"
              :customRow="customRow"
              :dataSource="leftDataSource"
              :pagination="false"
              :class="leftDataSource.length ? 'have-data' : ''"
              :loading="psdLoading"
              :scroll="{ x: 'max-content', y: 'calc(100vh - 460px)' }"
              :rowSelection="{ selectedRowKeys: selectedPsdRowKeys, onChange: onPsdSelectChange, columnWidth: 45 }"
              @change="handlePsdTableChange"
            >
              <span slot="cc" slot-scope="text, record">
                {{ record.width + '×' + record.height }}
              </span>
              <span slot="fileLength" slot-scope="text, record">
                {{ (record.fileLength / 1024 / 1024).toFixed(2) + 'M' }}
              </span>
            </a-table>
            <div v-else-if="leftShow" class="prog-div">
              <Progress :width="300" :progress="leftNum" :strokeWidth="10" :showInfo="true" />
            </div>
            <a-spin :spinning="psdLoading" v-else-if="viewType == 'widthView' && !leftShow">
              <div v-show="!leftDataSource.length && !leftShow" class="prog-div">
                <a-empty :image="simpleImage" style="margin:0 auto" />
              </div>
              <draggable
                v-show="leftDataSource.length && !leftShow"
                dragClass="drag-img"
                ghostClass="ghost"
                class="img-list"
                :animation="300"
                v-model="leftDataSource"
                @start="drag = true"
                @end="drag = false"
                @sort="dragEnd(leftDataSource)"
              >
                <div @click="selectLeftImg(item)" class="img-div" v-for="(item, index) in leftDataSource" :key="index">
                  <img :class="item.checked ? 'select-img' : ''" :src="item.filePath" style="width:140px" alt="" />
                  <div class="img-name">{{ item.fileName }}</div>
                </div>
              </draggable>
            </a-spin>
            <a-spin :spinning="psdLoading" v-else-if="viewType == 'heightView' && !leftShow">
              <div v-show="!leftDataSource.length" class="prog-div">
                <a-empty :image="simpleImage" style="margin:0 auto" />
              </div>
              <draggable
                v-show="leftDataSource.length"
                dragClass="drag-img"
                ghostClass="ghost"
                class="img-list"
                :animation="300"
                v-model="leftDataSource"
                @start="drag = true"
                @end="drag = false"
                @sort="dragEnd(leftDataSource)"
              >
                <div
                  @click="selectLeftImg(item)"
                  class="img-div-height"
                  v-for="(item, index) in leftDataSource"
                  :key="index"
                >
                  <img :class="item.checked ? 'select-img' : ''" :src="item.filePath" style="height:350px;" alt="" />
                  <div class="img-name">{{ item.fileName }}</div>
                </div>
              </draggable>
            </a-spin>
            <div class="select-num" v-if="viewType == 'list' && !leftShow">
              已选择：{{ selectedPsdRowKeys.length }}项，共{{ leftDataSource.length }}项
            </div>
          </div>
        </a-col>
        <a-col :span="12" class="right-content">
          <div class="left-content-button-list">
            <!--             <a-upload
              name="file"
              accept=".jpg"
              :showUploadList="false"
              :multiple="true"
              :action="ossUploadUrl"
              :data="ossUploadData"
              :before-upload="beforeRightUpload"
              :custom-request="
                options => {
                  handleUpload(options, 'JPG', 'jpg')
                }
              "
              @change="
                info => {
                  handleChangeUpload(info, 'JPG')
                }
              "
            >
              <a-button icon="upload" class="mr-3" type="primary">
                上传JPG
              </a-button>
            </a-upload> -->
            <defaultSizeUpload
              btnText="上传JPG"
              upType="JPG"
              :taskDetail="taskDetail"
              @startProgress="startProgress"
              @changeProgress="changeProgress"
              @changeTransProgress="changeTransProgress"
              @endProgress="endProgress"
              btnType="original"
              :sizeList="sizeList"
              :showFileList="rightDataSource"
              :loadData="getRightTableData"
              saveUrl="/kt/translate/uploadOriginalManuscript"
            />
            <defaultSizeReplace
              btnText="替换"
              upType="JPG"
              :taskDetail="taskDetail"
              @startProgress="startProgress"
              @changeProgress="changeProgress"
              @changeTransProgress="changeTransProgress"
              @endProgress="endProgress"
              btnType="original"
              :sizeList="sizeList"
              :loadData="getRightTableData"
              :showFileList="rightDataSource"
              :selectKeys="selectedJpgRowKeys"
              saveUrl="/original/manuscript/replace"
            />
            <a-button
              :disabled="!selectedJpgRowKeys.length"
              @click="deleteJpgImg"
              :loading="btnLoading"
              icon="delete"
              class="mr-3"
              type="danger"
            >
              删除
            </a-button>
          </div>
          <div class="left-content-list">
            <a-table
              v-if="viewType == 'list' && !rightShow"
              size="middle"
              rowKey="id"
              :columns="columnsRight"
              :dataSource="rightDataSource"
              :pagination="false"
              :loading="jpgLoading"
              :customRow="customRowJpg"
              :class="rightDataSource.length ? 'have-data' : ''"
              :scroll="{ x: 'max-content', y: 'calc(100vh - 460px)' }"
              :rowSelection="{ selectedRowKeys: selectedJpgRowKeys, onChange: onJpgSelectChange, columnWidth: 45 }"
              @change="handleJpgTableChange"
              ><!-- :pagination="jpgPagination" -->
              <span slot="cc" slot-scope="text, record">
                {{ record.width + '×' + record.height }}
              </span>
              <span slot="fileLength" slot-scope="text, record">
                {{ (record.fileLength / 1024 / 1024).toFixed(2) + 'M' }}
              </span>
            </a-table>
            <div v-else-if="rightShow" class="prog-div">
              <Progress :width="300" :progress="rightNum" :strokeWidth="10" :showInfo="true" />
            </div>
            <a-spin :spinning="jpgLoading" v-else-if="viewType == 'widthView' && !rightShow">
              <div v-show="!rightDataSource.length" class="prog-div">
                <a-empty :image="simpleImage" style="margin:0 auto" />
              </div>
              <draggable
                v-show="rightDataSource.length"
                dragClass="drag-img"
                ghostClass="ghost"
                class="img-list"
                :animation="300"
                v-model="rightDataSource"
                @start="drag = true"
                @end="drag = false"
                @sort="dragEnd(rightDataSource)"
              >
                <div
                  @click="selectRightImg(item)"
                  class="img-div"
                  v-for="(item, index) in rightDataSource"
                  :key="index"
                >
                  <img
                    @load="imgOnError(index)"
                    :class="item.checked ? 'select-img' : ''"
                    :src="item.filePath"
                    style="width:100%;height:max-content;"
                    alt=""
                  />
                  <div class="img-name">{{ item.fileName }}</div>
                </div>
              </draggable>
            </a-spin>
            <a-spin :spinning="jpgLoading" v-else-if="viewType == 'heightView' && !rightShow">
              <div v-show="!rightDataSource.length" class="prog-div">
                <a-empty :image="simpleImage" style="margin:0 auto" />
              </div>
              <draggable
                v-show="rightDataSource.length"
                dragClass="drag-img"
                ghostClass="ghost"
                class="img-list"
                :animation="300"
                v-model="rightDataSource"
                @start="drag = true"
                @end="drag = false"
                @sort="dragEnd(rightDataSource)"
              >
                <div
                  @click="selectRightImg(item)"
                  class="img-div-height"
                  v-for="(item, index) in rightDataSource"
                  :key="index"
                >
                  <img
                    @load="imgOnError(index)"
                    :class="item.checked ? 'select-img' : ''"
                    :src="item.filePath"
                    style="height:350px;"
                    alt=""
                  />
                  <div class="img-name" style="width:100%">{{ item.fileName }}</div>
                </div>
              </draggable>
            </a-spin>
            <div class="select-num" v-if="viewType == 'list' && !rightShow">
              已选择：{{ selectedJpgRowKeys.length }}项，共{{ rightDataSource.length }}项
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="joint-show" v-show="jointShow">
      <div>
        <img
          v-for="item in selectionPsdRows"
          :style="{ width: item.width + 'px' }"
          :key="item.id"
          :ref="'img-' + item.id"
          :src="item.filePath"
          alt=""
        />
        <img
          v-for="item in selectionJpgRows"
          :style="{ width: item.width + 'px' }"
          :key="item.id"
          :ref="'img-' + item.id"
          :src="item.filePath"
          alt=""
        />
      </div>
    </div>
    <font-modal ref="fontModal" @saveFont="saveFont" />
  </a-spin>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import fontModal from './font-modal'
import { mixinDevice } from '@/utils/mixin.js'
import * as imageConversion from 'image-conversion'
import actionsTasksBtn from '@/views/system/modules/mytasks/actionsTasksBtn'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import draggable from 'vuedraggable'
import Progress from '@/components/progress'
import { Empty } from 'ant-design-vue'
import { upload } from '@/utils/upload'
import { handleArrayParams } from '@/utils/util'
import { deleteAction } from '../../../../../api/manage'
import { changeDpiBlob } from '@/utils/changeDpi.js'
import defaultSizeUpload from '@/components/default-size-upload'
import defaultSizeReplace from '@/components/default-size-relace'
import html2canvas from 'html2canvas'
import axios from 'axios'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
export default {
  mixins: [UploadFileToOssMixins, mixinDevice],
  components: { defaultSizeReplace, actionsTasksBtn, draggable, Progress, defaultSizeUpload, fontModal },
  data() {
    return {
      psdLoading: false,
      jointShow: false,
      jpgLoading: false,
      psdPagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      jpgPagination: {
        current: 1,
        pageSize: 50,
        defaultPageSize: 50,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      params: {
        ...this.$route.params,
        record: this.$route.query.record
      },
      rightDataSource: [],
      leftDataSource: [],
      columns: [
        {
          title: '名称',
          dataIndex: 'fileName',
          sorter: (a, b) => a.index - b.index,
          width: 140
        },
        {
          title: '尺寸',
          dataIndex: 'cc',
          sorter: (a, b) => a.height - b.height,
          scopedSlots: { customRender: 'cc' },
          width: 105
        },
        {
          title: '分辨率',
          dataIndex: 'dpi',
          sorter: (a, b) => a.dpi - b.dpi,
          width: 105
        },
        {
          title: '大小',
          dataIndex: 'fileLength',
          sorter: (a, b) => a.fileLength - b.fileLength,
          scopedSlots: { customRender: 'fileLength' },
          width: 105
        },
        {
          title: '修改时间',
          sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
          dataIndex: 'lastModified',
          width: 200
        }
      ],
      columnsRight: [
        {
          title: '名称',
          dataIndex: 'fileName',
          sorter: (a, b) => a.index - b.index,
          width: 140
        },
        {
          title: '尺寸',
          dataIndex: 'cc',
          sorter: (a, b) => a.height - b.height,
          scopedSlots: { customRender: 'cc' },
          width: 105
        },
        {
          title: '分辨率',
          dataIndex: 'dpi',
          sorter: (a, b) => a.dpi - b.dpi,
          width: 105
        },
        {
          title: '大小',
          dataIndex: 'fileLength',
          sorter: (a, b) => a.fileLength - b.fileLength,
          scopedSlots: { customRender: 'fileLength' },
          width: 105
        },
        {
          title: '修改时间',
          sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
          dataIndex: 'lastModified',
          width: 200
        }
      ],
      url: {
        list: ''
      },
      spinning: false,
      btnLoadding: false,
      taskDetail: {
        productionName: '',
        platformName: '',
        chapterSequenceNo: ''
      },
      viewType: 'heightView',
      selectedPsdRowKeys: [],
      selectionPsdRows: [],
      selectedJpgRowKeys: [],
      selectionJpgRows: [],
      color: '',
      backColor: '',
      fileRightList: [],
      imgRights: [],
      rightArr: [],
      fileLeftList: [],
      sizeList: [],
      imgLefts: [],
      leftArr: [],
      rightNum: 0,
      rightShow: false,
      leftNum: 0,
      leftShow: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      uploadFileList: [],
      rightTransNum: 1,
      leftTransNum: 1,
      targetLang: '',
      jointWidth: 0,
      jointHeight: 0,
      userInfo: {},
      jointImgs: [],
      ossUploadDatas: [],
      ossFilePolicy: [],
      defaultDpi: 300,
      taskList: [],
      btnLoading: false,
      sourceObj: null,
      sourceObj: null,
      dragStartIndex: 0
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color,
        '--back-color': this.backColor
      }
    }
  },
  created() {
    if (sessionStorage.getItem('upload_left_sort')) {
      let sortData = JSON.parse(sessionStorage.getItem('upload_left_sort'))
      this.columns.forEach(c => {
        if (c.dataIndex == sortData.columnKey) {
          c.defaultSortOrder = sortData.order
        }
      })
    }
    if (sessionStorage.getItem('upload_right_sort')) {
      let sortData = JSON.parse(sessionStorage.getItem('upload_right_sort'))
      this.columnsRight.forEach(c => {
        if (c.dataIndex == sortData.columnKey) {
          c.defaultSortOrder = sortData.order
        }
      })
    }
    this.getAlldata()
    this.userInfo = Vue.ls.get(USER_INFO)
    this.viewType = this.$route.query.viewType || 'heightView'
  },
  watch: {
    viewType() {
      this.selectedJpgRowKeys = []
      this.selectedPsdRowKeys = []
    },
    rightNum() {
      console.log(this.rightNum)
    }
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.backColor = rule.style.backgroundColor
          }
        }
      }
    }, 100)
  },
  methods: {
    replaceJpgImg() {},
    async getTaskList() {
      const res = await getAction('/tasks/taskChapters', { taskId: this.taskDetail.taskId })
      try {
        if (res.code == 200) {
          this.taskList = res.data
        } else {
          this.message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    async next() {
      if (this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) == this.taskList.length - 1) return
      this.params.taskId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1
      ].taskId
      this.params.chapterId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1
      ].chapterId
      const record = this.taskList[this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1]
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${this.taskDetail.productName +
          '-' +
          record.chapterOrder +
          '-' +
          this.taskDetail.stageName}&type=上传原稿&viewType=${this.viewType}`
      })
    },
    async prev() {
      if (!this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId)) return
      this.params.taskId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1
      ].taskId
      this.params.chapterId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1
      ].chapterId
      const record = this.taskList[this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1]
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${this.taskDetail.productName +
          '-' +
          record.chapterOrder +
          '-' +
          this.taskDetail.stageName}&type=上传原稿&viewType=${this.viewType}`
      })
    },
    joint() {
      /* if (window.devicePixelRatio != 1) return this.$message.error('拼接前请重置浏览器缩放比例') */
      this.jointImgs = []
      if (this.selectionPsdRows.length && this.selectionJpgRows.length) {
        this.$message.error('无法同时拼接原稿与原稿JPG')
      } else if (this.selectionPsdRows.length && !this.selectionJpgRows.length) {
        this.$message.warning('拼接前请确认所有被拼接文件顺序正确，拼接完成的新文件会以被拼接的第一个文件名命名。')
        this.jointWidth = this.sizeList[0].size.psdWidth
        this.defaultDpi = this.sizeList[0].size.psdResolution
        this.spinning = true
        this.$nextTick(() => {
          let loadImgs = []
          let that = this
          this.selectionPsdRows.forEach(item => {
            if (!that.$refs['img-' + item.id]) return
            var img = that.$refs['img-' + item.id][0]
            if (img.complete) {
              setTimeout(() => {
                loadImgs.push(img)
                if (loadImgs.length == that.selectionPsdRows.length) {
                  const element = document.getElementsByClassName('joint-show')[0].childNodes[0]
                  this.jointHeight = element.clientHeight
                  html2canvas(element, {
                    useCORS: true,
                    width: this.sizeList[0].size.psdWidth,
                    x: 0,
                    y: 0,
                    height: element.clientHeight
                  }).then(canvas => {
                    let fileName = this.selectionPsdRows[0].fileName
                    const image = canvas.toDataURL('image/jpeg')
                    console.log(image, '加载完成')
                    this.getImage(image, fileName, 'PSD')
                  })
                }
              }, 0)
            } else {
              img.onload = () => {
                loadImgs.push(img)
                if (loadImgs.length == that.selectionPsdRows.length) {
                  const element = document.getElementsByClassName('joint-show')[0].childNodes[0]
                  this.jointHeight = element.clientHeight
                  html2canvas(element, {
                    useCORS: true,
                    width: this.sizeList[0].size.psdWidth,
                    x: 0,
                    y: 0,
                    height: element.clientHeight
                  }).then(canvas => {
                    let fileName = this.selectionPsdRows[0].fileName
                    const image = canvas.toDataURL('image/jpeg')
                    console.log(image, '加载完成')
                    this.getImage(image, fileName, 'PSD')
                  })
                }
              }
            }
          })
        })
      } else if (!this.selectionPsdRows.length && this.selectionJpgRows.length) {
        this.$message.warning('拼接前请确认所有被拼接文件顺序正确，拼接完成的新文件会以被拼接的第一个文件名命名。')
        this.spinning = true
        this.jointWidth = this.sizeList[0].size.jpgWidth
        this.defaultDpi = this.sizeList[0].size.jpgResolution
        this.$nextTick(() => {
          let loadImgs = []
          let that = this
          this.selectionJpgRows.forEach(item => {
            if (!that.$refs['img-' + item.id]) return
            var img = that.$refs['img-' + item.id][0]
            if (img.complete) {
              setTimeout(() => {
                loadImgs.push(img)
                if (loadImgs.length == that.selectionJpgRows.length) {
                  const element = document.getElementsByClassName('joint-show')[0].childNodes[0]
                  this.jointHeight = element.clientHeight
                  html2canvas(element, {
                    useCORS: true,
                    width: this.jointWidth,
                    x: 0,
                    y: 0,
                    height: element.clientHeight
                  }).then(canvas => {
                    let fileName = this.selectionJpgRows[0].fileName
                    const image = canvas.toDataURL('image/jpeg')
                    console.log({ canvas }, '加载完成')
                    this.getImage(image, fileName, 'JPG', canvas.width)
                  })
                }
              }, 0)
            } else {
              img.onload = () => {
                loadImgs.push(img)
                if (loadImgs.length == that.selectionJpgRows.length) {
                  const element = document.getElementsByClassName('joint-show')[0].childNodes[0]
                  this.jointHeight = element.clientHeight
                  html2canvas(element, {
                    useCORS: true,
                    width: this.jointWidth,
                    x: 0,
                    y: 0,
                    height: element.clientHeight
                  }).then(canvas => {
                    let fileName = this.selectionJpgRows[0].fileName
                    const image = canvas.toDataURL('image/jpeg')
                    console.log({ canvas }, '加载完成')
                    this.getImage(image, fileName, 'JPG', canvas.width)
                  })
                }
              }
            }
          })
        })
      }
      this.jointShow = true
    },
    formatDate(datetime) {
      if (datetime != null) {
        const dateMat = new Date(datetime)
        const year = dateMat.getFullYear()
        const month = dateMat.getMonth() + 1
        const day = dateMat.getDate()
        const hh = dateMat.getHours()
        const mm = dateMat.getMinutes()
        const ss = dateMat.getSeconds()
        const timeFormat =
          year +
          '-' +
          (month < 10 ? '0' : '') +
          month +
          '-' +
          (day < 10 ? '0' : '') +
          day +
          ' ' +
          (hh < 10 ? '0' : '') +
          hh +
          ':' +
          (mm < 10 ? '0' : '') +
          mm +
          ':' +
          (ss < 10 ? '0' : '') +
          ss
        return timeFormat
      }
    },

    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date() // 文件最后的修改日期
      theBlob.name = fileName // 文件名
      return new File([theBlob], fileName, { type: theBlob.type, lastModified: Date.now() })
    },
    getImage(image, fileName, type, clientWidth) {
      let that = this
      this.getFile(image).then(async oldBlob => {
        let file = this.blobToFile(oldBlob, fileName)
        let compressNum = clientWidth - this.jointWidth
        let res = null
        if (compressNum >= 100) {
          res = await imageConversion.compressAccurately(file, {
            size: ((file.size * (this.jointWidth / clientWidth)) / 1000).toFixed(0),
            width: this.jointWidth
          })
        } else {
          res = await imageConversion.compressAccurately(file, {
            size: ((file.size * 0.9) / 1000).toFixed(0),
            width: this.jointWidth
          })
        }

        changeDpiBlob(res, that.defaultDpi).then(blob => {
          const file = new File([blob], fileName, { type: 'image/jpeg' })
          setTimeout(() => {
            this.tokenAxios({
              url: '/oss',
              method: 'get'
            })
              .then(async res => {
                let uploadPath =
                  this.taskDetail.platformName +
                  '/' +
                  this.taskDetail.productionName +
                  '/' +
                  this.taskDetail.chapterSequenceNo +
                  '/' +
                  this.taskDetail.taskName +
                  '/' +
                  this.getGuid()
                let policy = {
                  policy: res.data.policy,
                  signature: res.data.signature,
                  ossaccessKeyId: res.data.accessid,
                  dir: res.data.dir,
                  host: res.data.host,
                  key: `${res.data.dir}glxt/scyg/psd/${this.userInfo.realname}/${uploadPath}/${file.name.replaceAll(
                    '+',
                    '加'
                  )}`
                }
                this.ossUploadDatas[file.name] = policy
                this.ossFilePolicy[file.name] = policy
                let formData = new FormData()
                for (let key in this.ossUploadDatas[file.name]) {
                  formData.append(key, this.ossUploadDatas[file.name][key])
                }
                formData.append('file', file)
                postAction(policy.host, formData)
                  .then(async rest => {
                    console.log('成功OSS', policy.host, formData)
                    let params = that.getSaveFileParams(file)
                    let saveParams = {
                      chapterId: that.taskDetail.chapterId,
                      dataList: [
                        {
                          fileName: file.name,
                          filePath: params.filePath,
                          fileType: type,
                          wasSuccess: true,
                          dpi: that.defaultDpi,
                          height: that.jointHeight,
                          width: that.jointWidth,
                          fileLength: file.size,
                          isSpliced: 1,
                          lastModified: that.formatDate(file.lastModifiedDate)
                        }
                      ]
                    }
                    const response = await postAction('/kt/translate/uploadOriginalManuscript', saveParams)
                    if (response.code == 200) {
                      if (type == 'JPG') {
                        const deleres = await deleteAction(
                          '/kt/translate/deleteOriginalManuscript?' +
                            new URLSearchParams(handleArrayParams({ ids: this.selectedJpgRowKeys })).toString()
                        )
                        if (deleres.code == 200) {
                          this.getRightTableData()
                        } else {
                          this.$message.error(res.msg)
                        }
                      } else {
                        const res = await deleteAction(
                          '/kt/translate/deleteOriginalManuscript?' +
                            new URLSearchParams(handleArrayParams({ ids: this.selectedPsdRowKeys })).toString()
                        )
                        if (res.code == 200) {
                          this.getLeftTableData()
                        } else {
                          this.$message.error(res.msg)
                        }
                      }
                    }
                    that.spinning = false
                    that.jointShow = false
                  })
                  .catch(err => {
                    that.spinning = false
                    that.jointShow = false
                    console.log('失败OSS', policy.host, formData)
                    console.log('上传失败')
                    that.$message.error(file.name + '上传OSS失败,请查看文件是否重名后联系管理员。')
                  })
              })
              .catch(err => {
                this.spinning = false
                this.jointShow = false
                this.$message.error('获取OSS签名失败')
              })
          }, 0)
        })
      })
    },
    getFile(sourceUrl) {
      let url = sourceUrl
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    getDetail() {
      getAction('/production/get', {
        id: this.taskDetail.productionId
      }).then(({ data }) => {
        this.targetLang = data.targetLang
      })
    },
    goToTranslate() {
      /*       this.$router.push({
        path: `/translate/editor/${this.taskDetail.taskId}/${this.taskDetail.productionId}/${this.taskDetail.chapterId}/${this.taskDetail.platformId}`
      }) */
      localStorage.setItem('translate_record', JSON.stringify(this.taskDetail))
      /*       window.open(
        `/translate/editor/${this.taskDetail.taskId}/${this.taskDetail.productionId}/${this.taskDetail.chapterId}/${this.taskDetail.platformId}?productionName=` +
          this.taskDetail.productionName+'&targetLang='+this.targetLang
      ) */
      localStorage.setItem('translate_download', '')
      /* window.open(`/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    startProgress(fileList, finishFileList, upType) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        this.rightShow = true
        if (this.rightNum < 80) return
        this.rightTransNum = 1
        this.rightNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        this.leftShow = true
        if (this.leftNum < 80) return
        this.leftTransNum = 1
        this.leftNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    changeProgress(fileList, finishFileList, upType) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        if (this.rightNum < 80) return
        this.rightNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      } else {
        if (this.leftNum < 80) return
        this.leftNum = ((finishFileList.length / fileList.length) * 20).toFixed(0) - 0 + 80
      }
    },
    saveFont(selectedRowKeys) {
      var ids = selectedRowKeys.join(',')
      var that = this
      let url = '/production/delete_guide_batch/'
      if (this.taskDetail.productionId) {
        url = `${url}/${this.taskDetail.productionId}`
      } else if (this.taskDetail.platformId) {
        url = `${url}/${this.taskDetail.platformId}`
      }
      deleteAction(url, { ids: ids }).then(res => {
        if (res.success) {
          that.$message.success(res.message || res.msg ? '保存成功' : '')
          that.getAlldata()
        } else {
          that.$message.warning(res.message || res.msg)
        }
      })
    },
    changeTransProgress(fileList, upType) {
      if (!fileList.length) return
      if (upType == 'JPG') {
        this.rightNum = ((this.rightTransNum / fileList.length) * 80).toFixed(0) - 0
        this.rightTransNum++
        if (this.rightNum > 78) {
          this.rightNum = 80
        }
      } else {
        this.leftNum = ((this.leftTransNum / fileList.length) * 80).toFixed(0) - 0
        this.leftTransNum++
        if (this.leftNum > 78) {
          this.leftNum = 80
        }
      }
    },
    endProgress(upType) {
      if (upType == 'JPG') {
        this.rightShow = false
        this.rightNum = 100
        setTimeout(() => {
          this.rightTransNum = 0
          this.rightNum = 0
        }, 500)
      } else {
        this.leftShow = false
        this.leftNum = 100
        setTimeout(() => {
          this.leftTransNum = 0
          this.leftNum = 0
        }, 500)
      }
    },
    async deleteJpgImg() {
      this.$confirm({
        content: `删除会将该文件上存在的译文、软硬伤、修改记录一起删除，请确认是否删除？`,
        onOk: async () => {
          const res = await deleteAction(
            '/kt/translate/deleteOriginalManuscript?' +
              new URLSearchParams(handleArrayParams({ ids: this.selectedJpgRowKeys })).toString()
          )
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.getRightTableData()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async deletePsdImg() {
      this.$confirm({
        content: `删除会将该文件上存在的译文、软硬伤、修改记录一起删除，请确认是否删除？`,
        onOk: async () => {
          this.btnLoading = true
          const res = await deleteAction(
            '/kt/translate/deleteOriginalManuscript?' +
              new URLSearchParams(handleArrayParams({ ids: this.selectedPsdRowKeys })).toString()
          )
          this.btnLoading = false
          if (res.code == 200) {
            this.getLeftTableData()
            this.$message.success('删除成功')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async noOcr() {
      let originalManuscriptIds = [
        ...this.selectionJpgRows.map(item => item.id),
        ...this.selectionPsdRows.map(item => item.id)
      ]
      let that = this
      this.$confirm({
        title: '提示',
        content: '确认清除？',
        async onOk() {
          const res = await postAction('/clear/ocr', {
            originalManuscriptIds,
            chapterIds: [that.taskDetail.chapterId]
          })
          if (res.code == 200) {
            that.$message.success('清除成功')
            that.getAlldata()
          } else {
            that.$message.error(res.msg)
          }
        }
      })
    },
    async ocr() {
      this.btnLoadding = true
      let ids = []
      if (this.selectionPsdRows.length) {
        ids = this.selectionPsdRows
          .filter(item => !item.ocrInfoList || (item.ocrInfoList && !item.ocrInfoList.length))
          .map(item => item.id)
      } else {
        ids = this.leftDataSource
          .filter(item => !item.ocrInfoList || (item.ocrInfoList && !item.ocrInfoList.length))
          .map(item => item.id)
      }
      if (!ids.length) {
        this.$message.error('没有需要OCR的数据')
        this.btnLoadding = false
        return
      }
      const res = await postAction('/kt/translate/originalManuscript/ocr', {
        ids,
        chapterIds: [this.taskDetail.chapterId]
      })
      if (res.code == 200) {
        this.$message.success('识别成功')
        this.getAlldata()
      } else {
        this.$message.error(res.msg)
      }
      this.btnLoadding = false
    },

    async translate(type) {
      let ids = [...this.selectedPsdRowKeys, ...this.selectedJpgRowKeys]
      let checkIds = []
      if (this.selectionPsdRows.length) {
        checkIds = this.selectionPsdRows.filter(item => !item.ocrInfoList).map(item => item.id)
      } else {
        checkIds = this.leftDataSource.filter(item => !item.ocrInfoList).map(item => item.id)
      }
      if (checkIds.length) {
        this.$message.error('有文件没有OCR，请重新操作OCR后再试')
        this.btnLoadding = false
        return
      }
      let arr = []
      ids.forEach(id => {
        this.leftDataSource.forEach(item => {
          if (item.id == id) {
            arr.push(item)
          }
        })
        this.rightDataSource.forEach(item => {
          if (item.id == id) {
            arr.push(item)
          }
        })
      })
      if (arr.some(item => item.hasTranslated)) {
        this.$confirm({
          content: `选中数据已有机翻内容，切换机翻会清空已有内容并替换为新的机翻结果，确认操作？`,
          zIndex: 9999,
          onOk: async () => {
            this.btnLoadding = true
            const res = await postAction('/kt/translate/originalManuscript/translate', {
              ids,
              operator: type,
              chapterIds: [this.taskDetail.chapterId]
            })
            if (res.code == 200) {
              this.$message.success('翻译成功')
              this.getAlldata()
            } else {
              this.$message.error(res.msg)
            }
            this.btnLoadding = false
          }
        })
        return
      } else {
        this.btnLoadding = true
        const res = await postAction('/kt/translate/originalManuscript/translate', {
          ids,
          operator: type,
          chapterIds: [this.taskDetail.chapterId]
        })
        if (res.code == 200) {
          this.$message.success('翻译成功')
          this.getAlldata()
        } else {
          this.$message.error(res.msg)
        }
        this.btnLoadding = false
      }
      return
      const rest = await getAction(
        '/check/translate/status?chapterId=' + this.taskDetail.chapterId + '&ids=' + checkIds.join(',')
      )
      if (rest.code == 200) {
        if (rest.success) {
          this.$confirm({
            content: `当前稿件已有机翻内容，切换机翻会清空已有内容并替换为新的机翻结果，确认操作？`,
            zIndex: 9999,
            onOk: async () => {
              this.btnLoadding = true
              const res = await postAction('/kt/translate/originalManuscript/translate', {
                ids,
                operator: type,
                chapterIds: [this.taskDetail.chapterId]
              })
              if (res.code == 200) {
                this.$message.success('翻译成功')
                this.getAlldata()
              } else {
                this.$message.error(res.msg)
              }
              this.btnLoadding = false
            }
          })
          return
        } else {
          this.btnLoadding = true
          const res = await postAction('/kt/translate/originalManuscript/translate', {
            ids,
            operator: type,
            chapterIds: [this.taskDetail.chapterId]
          })
          if (res.code == 200) {
            this.$message.success('翻译成功')
            this.getAlldata()
          } else {
            this.$message.error(res.msg)
          }
          this.btnLoadding = false
        }
      } else {
        this.btnLoadding = false
      }
    },
    async dragEnd(dataList) {
      dataList.forEach((item, index) => {
        item.sequenceNo = index
      })
      const res = await postAction('/origin/manuscript/rearrangement', dataList)
      if (res.code != 200) {
        this.$message.error(res.msg)
      }
    },
    formatDate(datetime) {
      if (datetime != null) {
        const dateMat = new Date(datetime)
        const year = dateMat.getFullYear()
        const month = dateMat.getMonth() + 1
        const day = dateMat.getDate()
        const hh = dateMat.getHours()
        const mm = dateMat.getMinutes()
        const ss = dateMat.getSeconds()
        const timeFormat =
          year +
          '-' +
          (month < 10 ? '0' : '') +
          month +
          '-' +
          (day < 10 ? '0' : '') +
          day +
          ' ' +
          (hh < 10 ? '0' : '') +
          hh +
          ':' +
          (mm < 10 ? '0' : '') +
          mm +
          ':' +
          (ss < 10 ? '0' : '') +
          ss
        return timeFormat
      }
    },
    async getRightTableData() {
      let params = {
        current: /* this.jpgPagination.current */ 1,
        size: /* this.jpgPagination.pageSize */ -1,
        chapterId: this.taskDetail.chapterId,
        fileType: 'JPG'
      }
      this.jpgLoading = true
      const res = await getAction('/kt/translate/getOriginalManuscript', params)
      if (res.code == 200) {
        this.selectedJpgRowKeys = []
        this.selectionJpgRows = []
        this.rightDataSource = res.data.records
        this.rightDataSource.forEach((item, index) => {
          item.checked = false
          item.index = index
        })
        this.jpgLoading = false
      }
    },
    async getLeftTableData() {
      let params = {
        current: /* this.psdPagination.current */ 1,
        size: /* this.psdPagination.pageSize */ -1,
        chapterId: this.taskDetail.chapterId,
        fileType: 'PSD'
      }
      this.psdLoading = true
      const res = await getAction('/kt/translate/getOriginalManuscript', params)
      if (res.code == 200) {
        this.selectedPsdRowKeys = []
        this.selectionPsdRows = []
        this.leftDataSource = res.data.records
        this.leftDataSource.forEach((item, index) => {
          item.checked = false
          item.index = index
        })
        this.psdLoading = false
      }
    },
    imgOnError(index) {
      if (this.fileRightList[index]) this.fileRightList[index].isLoad = true
      this.rightNum =
        (this.fileRightList.filter(item => item.isLoad).length / this.fileRightList.length).toFixed(2) * 100 - 0
      if (this.rightNum == 100) {
        setTimeout(() => {
          this.rightShow = false
        }, 800)
      }
    },
    selectRightImg(item) {
      if (item.checked) {
        item.checked = false
        if (this.selectedJpgRowKeys.indexOf(item.id) > -1) {
          this.selectedJpgRowKeys.splice(this.selectedJpgRowKeys.indexOf(item.id), 1)
          this.selectionJpgRows.splice(this.selectedJpgRowKeys.indexOf(item.id), 1)
        }
      } else {
        item.checked = true
        if (this.selectedJpgRowKeys.indexOf(item.id) == -1) {
          this.selectedJpgRowKeys.push(item.id)
          this.selectionJpgRows.push(item)
        }
        /* this.selectedJpgRowKeys = selectedRowKeys
      this.selectionJpgRows = selectionRows */
      }
      this.$forceUpdate()
    },
    beforeRightUpload(file, e) {
      this.jpgLoading = true
      return this.ossPolicy(file.name)
    },
    beforeLeftUpload(file, e) {
      this.psdLoading = true
      return this.ossPolicy(file.name)
    },
    handleRightChange(info) {
      this.rightShow = true
      this.rightArr.push(info)
      this.rightDataSource = []
      if (this.rightArr.length == info.fileList.length) {
        this.rightArr.forEach((item, i) => {
          this.fileRightList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.result
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  obj = {
                    cc: item.imgWidth + '*' + item.imgHeight,
                    fileSize: imgSize,
                    id: item.file.name,
                    fileName: item.file.name
                  }
                  this.rightDataSource.push({ ...obj, sort: this.rightDataSource.length + 1 })
                  if (this.rightDataSource.length == this.fileRightList.length) {
                    this.jpgLoading = false
                  }
                }
              }
            }
          })
        })
        this.imgRights = []
        this.rightArr.forEach(item => {
          this.imgRights.push(item.url)
        })
      }
    },
    customRow(record, index) {
      var that = this
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        on: {
          // 鼠标移入
          mouseenter: event => {
            // 兼容IE
            var ev = event || window.event
            ev.target.draggable = true
          },
          // 开始拖拽
          dragstart: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 得到源目标数据
            // console.log(that);
            that.dragStartIndex = index
            that.sourceObj = record
          },
          // 拖动元素经过的元素
          dragover: event => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为
            ev.preventDefault()
          },
          // 鼠标松开
          drop: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 注意=>这里会调换数据,记得注释
            let _data = JSON.parse(JSON.stringify(that.leftDataSource))
            /* _data[that.dragStartIndex] = that.leftDataSource[index] */
            _data[index] = that.leftDataSource[that.dragStartIndex]
            if (that.dragStartIndex < index) {
              for (let i = that.dragStartIndex; i < index; i++) {
                if (that.leftDataSource[i + 1]) {
                  _data[i] = that.leftDataSource[i + 1]
                }
              }
            } else {
              for (let i = index; i < that.dragStartIndex; i++) {
                if (that.leftDataSource[i + 1]) {
                  _data[i + 1] = that.leftDataSource[i]
                }
              }
            }

            that.leftDataSource = _data
            that.dragEnd(that.leftDataSource)
          }
        }
      }
    },
    customRowJpg(record, index) {
      var that = this
      return {
        // FIXME: draggable： true 不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        on: {
          // 鼠标移入
          mouseenter: event => {
            // 兼容IE
            var ev = event || window.event
            ev.target.draggable = true
          },
          // 开始拖拽
          dragstart: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 得到源目标数据
            // console.log(that);
            that.dragStartIndex = index
            that.sourceObj = record
          },
          // 拖动元素经过的元素
          dragover: event => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为
            ev.preventDefault()
          },
          // 鼠标松开
          drop: event => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 注意=>这里会调换数据,记得注释
            let _data = JSON.parse(JSON.stringify(that.rightDataSource))
            /* _data[that.dragStartIndex] = that.leftDataSource[index] */
            _data[index] = that.rightDataSource[that.dragStartIndex]
            if (that.dragStartIndex < index) {
              for (let i = that.dragStartIndex; i < index; i++) {
                if (that.rightDataSource[i + 1]) {
                  _data[i] = that.rightDataSource[i + 1]
                }
              }
            } else {
              for (let i = index; i < that.dragStartIndex; i++) {
                if (that.rightDataSource[i + 1]) {
                  _data[i + 1] = that.rightDataSource[i]
                }
              }
            }
            that.rightDataSource = _data
            that.dragEnd(that.rightDataSource)
          }
        }
      }
    },
    async handleUpload(options, type, upType) {
      let formData = new FormData(),
        file = options.file
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      let data = new FormData()
      let dpi = 0
      data.append('files', file)
      const rest = await postAction('/kt/translate/getDpi', data)
      if (rest.code == 200 && rest.data) {
        dpi = rest.data[0]
      }
      let index = this.uploadFileList.push(file) - 1
      upload(this.ossUploadData.host, formData, percent => this.setUploadProcess(percent, file, index, upType)).then(
        async () => {
          //成功处理
          Object.assign(file, { status: 'done' })
          this.uploadFileList.splice(index, 1, file)
          let params = this.getSaveFileParams(file)
          console.log(file)
          let that = this
          var read = new FileReader()
          read.readAsDataURL(file)
          read.onload = function(e) {
            var img = new Image()
            img.src = e.target.result
            let size = null
            if (that.sizeList.length) {
              size = that.sizeList[0]
            } else {
              this.$message.error('未获取到默认尺寸，无法上传图片')
              return
            }
            img.onload = async function() {
              /*               if (upType == 'original') {
                if (size.size.psdFormat.indexOf(type) == -1) {
                  that.$message.error('格式错误')
                  that.avatarLoading = false
                  return
                } else if (this.height > size.size.psdMaxHeight || this.height < size.size.psdMinHeight) {
                  that.$message.error('高度错误')
                  that.avatarLoading = false
                  return
                } else if (this.width != size.size.psdWidth) {
                  that.$message.error('宽度错误')
                  that.avatarLoading = false
                  return
                } else if (dpi != size.size.psdResolution) {
                  that.$message.error('分辨率错误')
                  that.avatarLoading = false
                  return
                }
              } else {
                if (size.size.jpgFormat.indexOf(type) == -1) {
                  that.$message.error('格式错误')
                  that.avatarLoading = false
                  return
                } else if (this.height > size.size.jpgMaxHeight || this.height < size.size.jpgMinHeight) {
                  that.$message.error('高度错误')
                  that.avatarLoading = false
                  return
                } else if (this.width != size.size.jpgWidth) {
                  that.$message.error('宽度错误')
                  that.avatarLoading = false
                  return
                } else if (dpi != size.size.jpgResolution) {
                  that.$message.error('分辨率错误')
                  that.avatarLoading = false
                  return
                }
              } */
              if (size.size.psdFormat.indexOf(type) == -1) {
                that.$message.error('格式错误')
                that.avatarLoading = false
                that.spinning = false
                return
              } else if (this.height > size.size.psdMaxHeight || this.height < size.size.psdMinHeight) {
                that.$message.error('高度错误')
                that.avatarLoading = false
                that.spinning = false
                return
              } else if (this.width != size.size.psdWidth) {
                that.$message.error('宽度错误')
                that.avatarLoading = false
                that.spinning = false
                return
              } else if (dpi != size.size.psdResolution) {
                that.$message.error('分辨率错误')
                that.avatarLoading = false
                that.spinning = false
                return
              }
              // 默认按比例压缩
              let data = {
                chapterId: that.taskDetail.chapterId,
                dataList: [
                  {
                    fileName: file.name,
                    filePath: params.filePath,
                    fileType: type,
                    wasSuccess: true,
                    dpi,
                    height: this.height,
                    width: this.width,
                    fileLength: file.size,
                    lastModified: that.formatDate(file.lastModifiedDate)
                  }
                ]
              }
              const res = await postAction('/kt/translate/uploadOriginalManuscript', data)

              if (res.code == 200) {
                if (upType == 'original') {
                  that.getLeftTableData()
                } else {
                  that.getRightTableData()
                }
                that.$message.success(file.name + '上传成功')
              }
              that.removeOssFilePolicy(file)
              that.avatarLoading = false
            }
          }
        },
        err => {
          //失败处理
          Object.assign(file, { status: 'error', message: '上传失败' })
          this.uploadFileList.splice(index, 1, file)
          if (upType == 'original') {
            this.topProgress = 99
            this.topStatus = 'exception'
          } else {
            this.bottomProgress = 99
            this.bottomStatus = 'exception'
          }
        }
      )
    },
    setUploadProcess(percent, file, index, upType) {
      Object.assign(file, { percent })
      this.uploadFileList.splice(index, 1, file)
      let num = 0
      this.uploadFileList.forEach(item => {
        num = num + item.percent
      })
      if (upType == 'original') {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.topProgress = progres
      } else {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.bottomProgress = progres
      }
    },
    async handleChangeUpload(info, type, record) {
      if (info.file.status === 'uploading') {
        return
      }
      if (info.file.status === 'done') {
      }
    },
    selectLeftImg(item) {
      if (item.checked) {
        item.checked = false
        if (this.selectedPsdRowKeys.indexOf(item.id) > -1) {
          this.selectedPsdRowKeys.splice(this.selectedPsdRowKeys.indexOf(item.id), 1)
          this.selectionPsdRows.splice(this.selectionPsdRows.indexOf(item.id), 1)
        }
      } else {
        item.checked = true
        if (this.selectedPsdRowKeys.indexOf(item.id) == -1) {
          this.selectedPsdRowKeys.push(item.id)
          this.selectionPsdRows.push(item)
        }
      }
      this.$forceUpdate()
    },
    /*     beforeLeftUpload(file, e) {
      this.psdLoading = true
      file.checked = false
      this.fileLeftList = [...this.fileLeftList, file]
      this.fileLeftList.sort((a, b) => {
        let result = a.name.match(/(\d+)[^0-9]*$/)[1]
        let resultB = b.name.match(/(\d+)[^0-9]*$/)[1]
        return result - resultB
      })
      return false
    }, */
    handleLeftChange(info) {
      this.leftShow = true
      this.leftArr.push(info)
      this.leftDataSource = []
      if (this.leftArr.length == info.fileList.length) {
        this.leftArr.forEach((item, i) => {
          this.fileLeftList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.result
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  obj[item.file.name] = { imgWidth: item.imgWidth, imgHeight: item.imgHeight, size: imgSize }
                  this.imgSizeLeftList = Object.assign({}, this.imgSizeLeftList, obj)
                }
              }
            }
          })
        })
        this.imgLefts = []
        this.leftArr.forEach(item => {
          this.imgLefts.push(item.url)
        })
        this.psdLoading = false
      }
    },
    onPsdSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedPsdRowKeys = selectedRowKeys
      this.selectionPsdRows = selectionRows
    },
    onJpgSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedJpgRowKeys = selectedRowKeys
      this.selectionJpgRows = selectionRows
    },
    handlePsdTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.psdPagination = pagination
      if (sorter.order) {
        let data = { columnKey: sorter.columnKey, order: sorter.order }
        sessionStorage.setItem('upload_left_sort', JSON.stringify(data))
      } else {
        sessionStorage.setItem('upload_left_sort', '')
      }
      this.loadPsdData()
    },
    handleJpgTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.jpgPagination = pagination
      if (sorter.order) {
        let data = { columnKey: sorter.columnKey, order: sorter.order }
        sessionStorage.setItem('upload_right_sort', JSON.stringify(data))
      } else {
        sessionStorage.setItem('upload_right_sort', '')
      }
      this.loadJpgData()
    },
    reloadData() {
      this.getAlldata()
    },
    getAlldata() {
      this.getTask()
    },
    skip() {
      window.history.go(-1)
    },
    async getTask() {
      this.spinning = true
      const res = await getAction('tasks/get/' + this.params.taskId)
      try {
        this.taskDetail = res.data
        this.taskDetail.showName = this.taskDetail.productionName || this.taskDetail.foreignName
        this.getRightTableData()
        this.getLeftTableData()
        this.getSizeList()
        this.getDetail()
        this.getTaskList()
        this.getFontList()
        this.spinning = false
      } catch (err) {
        console.log(err)
        this.spinning = false
      }
    },
    async getSizeList() {
      const res = await getAction(
        '/kt/translate/manuscript/specification?' +
          new URLSearchParams(handleArrayParams({ productIds: [this.taskDetail.productionId] })).toString()
      )
      if (res.code == 200) {
        this.sizeList = res.data
        if (this.$refs.defaultSizeUpload) {
          this.$refs.defaultSizeUpload.defaultMaxHeight = this.sizeList[0].size.psdMaxHeight
          this.$refs.defaultSizeUpload.defaultMinHeight = this.sizeList[0].size.psdMinHeight
          this.$refs.defaultSizeUpload.defaultWidth = this.sizeList[0].size.psdWidth
          this.$refs.defaultSizeUpload.defaultType = this.sizeList[0].size.psdFormat
          this.$refs.defaultSizeUpload.defaultDpi = this.sizeList[0].size.psdResolution
        }
        if (!this.sizeList.length) {
          this.$message.error('未获取到默认尺寸，无法上传图片')
        }
      } else {
        this.$message.error('未获取到默认尺寸，无法上传图片')
      }
    },
    async getFontList() {
      const res = await getAction('/production/get_guide', {
        current: 1,
        fieId: 'guidName',
        id: this.taskDetail.productionId,
        size: -1,
        typeId: 1
      })
      if (res.code == 200) {
        if (res.data.records.filter(item => item.classId == 4).length > 1) {
          this.$refs.fontModal.openModal(res.data.records.filter(item => item.classId == 4))
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    changeViewType(viewType) {
      this.viewType = viewType
      this.selectedJpgRowKeys = []
      this.selectedPsdRowKeys = []
      this.selectionJpgRows = []
      this.selectionPsdRows = []
      this.leftDataSource.forEach(item => {
        item.checked = false
      })
      this.rightDataSource.forEach(item => {
        item.checked = false
      })
      if (sessionStorage.getItem('upload_left_sort')) {
        let sortData = JSON.parse(sessionStorage.getItem('upload_left_sort'))
        this.columns.forEach(c => {
          if (c.dataIndex == sortData.columnKey) {
            c.defaultSortOrder = sortData.order
          }
        })
      }
      if (sessionStorage.getItem('upload_right_sort')) {
        let sortData = JSON.parse(sessionStorage.getItem('upload_right_sort'))
        this.columnsRight.forEach(c => {
          if (c.dataIndex == sortData.columnKey) {
            c.defaultSortOrder = sortData.order
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .have-data .ant-table-body {
  height: calc(-460px + 100vh) !important;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  padding-top: 10px;
  .img-div {
    width: 150px;
    display: flex;
    margin-left: 25px;
    justify-content: center;
    flex-wrap: wrap;
    img {
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
    }
    .select-img {
      box-shadow: 2px 1px 6px 2px var(--theme-color);
    }
    .img-name {
      width: 140px;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      white-space: pre-line;
      word-wrap: break-word;
    }
  }
  .img-div-height {
    text-align: center;
    margin-left: 10px;
    max-width: 45%;
    img {
      box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
      max-width: 90%;
    }
    .select-img {
      box-shadow: 2px 1px 6px 2px var(--theme-color);
    }
    .img-name {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      white-space: pre-line;
      word-wrap: break-word;
    }
  }
}
/deep/ .ant-table-row-cell-break-word {
  padding: 6px !important;
  border: none !important;
}
.prog-div {
  position: absolute;
  height: calc(100vh - 400px);
  top: -5px;
  /* background-color: rgba(0, 0, 0, 0.25); */
  width: calc(100% - 25px);
  display: flex;
  align-items: center;
}
.my-task {
  padding-top: 5vh;
  padding-bottom: 2vh;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  position: relative;
}
.content {
  padding-top: 40px;
}
.left-button-list {
  width: 150px;
}
.right-button-list {
  float: right;
  display: flex;
}
.left-content {
  padding-right: 50px;
  .left-content-button-list {
    height: 32px;
    margin-top: 20px;
  }
  .left-content-list {
    margin-top: 25px;
    /*     padding: 25px;
    padding-left:0px;
    padding-right:0px; */
    height: calc(100vh - 375px);
    padding-bottom: 22px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto;
    position: relative;
  }
}
.left,
.right {
  position: absolute;
  width: 2vw;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    cursor: default;
  }
  i {
    color: rgba(0, 0, 0, 0.1);
  }
  &:hover {
    i {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        ~ span {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }
}
.ghost {
  opacity: 0 !important;
}
.right-content {
  padding-left: 50px;
  .left-content-button-list {
    height: 32px;
    margin-top: 20px;
  }
  .left-content-list {
    margin-top: 25px;
    /*     padding: 25px;
    padding-left:0px;
    padding-right:0px; */
    padding-bottom: 22px;
    height: calc(100vh - 375px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto;
    position: relative;
  }
}
.left-content-list::-webkit-scrollbar-button {
  height: 0;
}
.left-content-list::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
.left-content-list::-webkit-scrollbar-track {
  border-radius: 0;
}
.left-content-list::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.left-content-list::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
/deep/ .ant-table-row:hover {
  .ant-table-row-cell-break-word {
    background-color: var(--back-color) !important;
  }
}
.left {
  left: 0;
}
.right {
  right: 0;
}
/deep/ .ant-table-placeholder {
  border-bottom: none !important;
}
/deep/ .ant-table-header {
  height: 52px !important;
}
.select-num {
  position: absolute;
  bottom: 5px;
  line-height: 32px;
  left: 20px;
}
.joint-show {
  height: 300px;
  position: absolute;
  left: 20%;
  top: 0;
  width: 100px;
  overflow-y: auto;
  background-color: black;
  opacity: 0;
  z-index: 1000;
  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
