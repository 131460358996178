var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.btnLoadding || _vm.spinning } },
    [
      _c(
        "div",
        { staticClass: "my-task", style: _vm.backCss },
        [
          _vm.taskList && _vm.taskList.length
            ? _c(
                "div",
                {
                  staticClass: "left",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _vm.taskList.findIndex(
                    (item) => item.taskId == _vm.taskDetail.taskId
                  )
                    ? _c("a-icon", {
                        style: _vm.isDesktop()
                          ? "font-size:30px"
                          : "font-size:15px",
                        attrs: { type: "left-circle", theme: "filled" },
                        on: { click: _vm.prev },
                      })
                    : _vm._e(),
                  _vm.taskList.findIndex(
                    (item) => item.taskId == _vm.taskDetail.taskId
                  )
                    ? _c(
                        "span",
                        {
                          style: _vm.isDesktop()
                            ? "font-size:25px"
                            : "font-size:12px",
                        },
                        [_vm._v(_vm._s(_vm.taskDetail.chapterSequenceNo - 1))]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.taskList && _vm.taskList.length
            ? _c(
                "div",
                {
                  staticClass: "right",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _vm.taskList.findIndex(
                    (item) => item.taskId == _vm.taskDetail.taskId
                  ) !=
                  _vm.taskList.length - 1
                    ? _c("a-icon", {
                        style: _vm.isDesktop()
                          ? "font-size:30px"
                          : "font-size:15px",
                        attrs: { type: "right-circle", theme: "filled" },
                        on: { click: _vm.next },
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      style: _vm.isDesktop()
                        ? "font-size:25px"
                        : "font-size:12px",
                    },
                    [_vm._v(_vm._s(_vm.taskDetail.chapterSequenceNo - 0 + 1))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-row",
            [
              _c(
                "a-breadcrumb",
                { attrs: { separator: ">" } },
                [
                  _c(
                    "a-breadcrumb-item",
                    { staticStyle: { "font-size": "16px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.skip },
                        },
                        [_vm._v("我的任务")]
                      ),
                    ]
                  ),
                  _c(
                    "a-breadcrumb-item",
                    { staticStyle: { "font-size": "16px" } },
                    [
                      _c("span", { staticStyle: { cursor: "pointer" } }, [
                        _vm._v("上传原稿"),
                      ]),
                    ]
                  ),
                  _c(
                    "a-breadcrumb-item",
                    {
                      staticStyle: { "font-size": "16px" },
                      attrs: { href: "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.taskDetail.chapterSequenceNo
                              ? _vm.taskDetail.showName +
                                  " - " +
                                  _vm.taskDetail.chapterSequenceNo +
                                  "-" +
                                  _vm.taskDetail.stageName
                              : _vm.taskDetail.showName +
                                  "-" +
                                  _vm.taskDetail.stageName
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "content" },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "left-button-list" },
                  [
                    _c(
                      "a-button-group",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("a-button", {
                          style:
                            _vm.viewType == "list"
                              ? "width:45px;z-index:9"
                              : "width:45px",
                          attrs: {
                            icon: "unordered-list",
                            type: _vm.viewType == "list" ? "primary" : "",
                            ghost: _vm.viewType == "list" ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeViewType("list")
                            },
                          },
                        }),
                        _c(
                          "a-button",
                          {
                            style:
                              _vm.viewType == "widthView"
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              type:
                                _vm.viewType == "widthView" ? "primary" : "",
                              ghost: _vm.viewType == "widthView" ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("widthView")
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-dengkuanfenbu icon",
                              style:
                                _vm.viewType == "widthView"
                                  ? `color:${this.color} !important`
                                  : "color:black !important;",
                            }),
                          ]
                        ),
                        _c(
                          "a-button",
                          {
                            style:
                              _vm.viewType == "heightView"
                                ? "width:45px;z-index:9"
                                : "width:45px",
                            attrs: {
                              type:
                                _vm.viewType == "heightView" ? "primary" : "",
                              ghost:
                                _vm.viewType == "heightView" ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeViewType("heightView")
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-denggaofenbu icon",
                              style:
                                _vm.viewType == "heightView"
                                  ? `color:${this.color} !important`
                                  : "color:black !important;",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "right-button-list" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mr-3",
                        attrs: { loading: _vm.btnLoadding, icon: "database" },
                        on: { click: _vm.joint },
                      },
                      [_vm._v("\n            拼接\n          ")]
                    ),
                    _vm.taskDetail.isMachineTranslation
                      ? _c(
                          "a-dropdown-button",
                          {
                            staticClass: "mr-3",
                            attrs: { trigger: ["click"] },
                            on: {
                              click: function ($event) {
                                _vm.btnLoadding ? () => {} : _vm.translate(0)
                              },
                            },
                          },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.translate(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" 切换机翻 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              [
                                _c("a-icon", { attrs: { type: "diff" } }),
                                _vm._v("\n              机翻\n            "),
                              ],
                              1
                            ),
                            _vm.taskDetail.isMachineTranslation
                              ? _c("a-icon", {
                                  attrs: { slot: "icon", type: "down" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  slot: "icon",
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-dropdown-button",
                      {
                        staticClass: "mr-3",
                        attrs: {
                          type: "primary",
                          spinning: _vm.btnLoadding,
                          trigger: ["click"],
                        },
                        on: {
                          click: function ($event) {
                            _vm.btnLoadding ? () => {} : _vm.ocr()
                          },
                        },
                      },
                      [
                        _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c(
                              "a-menu-item",
                              { key: "2", on: { click: _vm.noOcr } },
                              [_vm._v("清除结果")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { on: { click: _vm.ocr } },
                          [
                            _c("a-icon", { attrs: { type: "scan" } }),
                            _vm._v("\n              OCR\n            "),
                          ],
                          1
                        ),
                        _c("a-icon", {
                          attrs: { slot: "icon", type: "down" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          slot: "icon",
                        }),
                      ],
                      1
                    ),
                    (_vm.taskDetail.stageName == "翻译" ||
                      _vm.taskDetail.stageName == "润色" ||
                      _vm.taskDetail.stageName == "校对" ||
                      _vm.taskDetail.stageName == "制作" ||
                      _vm.taskDetail.stageName == "监制" ||
                      _vm.taskDetail.stageName == "终审" ||
                      _vm.taskDetail.stageName == "给稿") &&
                    _vm.taskDetail.applyTranslation
                      ? _c(
                          "a-button",
                          {
                            staticClass: "mr-3",
                            on: { click: _vm.goToTranslate },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-fanyi icon",
                              staticStyle: {
                                color: "rgba(0,0,0,0.65)",
                                "font-size": "18px",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c("actions-tasks-btn", {
                      attrs: {
                        propsParams: _vm.taskDetail,
                        loadData: _vm.reloadData,
                        taskList: _vm.taskList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticClass: "left-content", attrs: { span: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "left-content-button-list" },
                    [
                      _c("defaultSizeUpload", {
                        ref: "defaultSizeUpload",
                        attrs: {
                          btnText: "上传原稿",
                          upType: "PSD",
                          taskDetail: _vm.taskDetail,
                          btnType: "original",
                          sizeList: _vm.sizeList,
                          loadData: _vm.getLeftTableData,
                          showFileList: _vm.leftDataSource,
                          saveUrl: "/kt/translate/uploadOriginalManuscript",
                        },
                        on: {
                          startProgress: _vm.startProgress,
                          changeProgress: _vm.changeProgress,
                          changeTransProgress: _vm.changeTransProgress,
                          endProgress: _vm.endProgress,
                        },
                      }),
                      _c("defaultSizeReplace", {
                        attrs: {
                          btnText: "替换",
                          upType: "PSD",
                          taskDetail: _vm.taskDetail,
                          btnType: "original",
                          sizeList: _vm.sizeList,
                          loadData: _vm.getLeftTableData,
                          showFileList: _vm.leftDataSource,
                          selectKeys: _vm.selectedPsdRowKeys,
                          saveUrl: "/original/manuscript/replace",
                        },
                        on: {
                          startProgress: _vm.startProgress,
                          changeProgress: _vm.changeProgress,
                          changeTransProgress: _vm.changeTransProgress,
                          endProgress: _vm.endProgress,
                        },
                      }),
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            disabled: !_vm.selectedPsdRowKeys.length,
                            loading: _vm.btnLoading,
                            icon: "delete",
                            type: "danger",
                          },
                          on: { click: _vm.deletePsdImg },
                        },
                        [_vm._v("\n            删除\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "left-content-list" },
                    [
                      _vm.viewType == "list" && !_vm.leftShow
                        ? _c("a-table", {
                            class: _vm.leftDataSource.length ? "have-data" : "",
                            attrs: {
                              size: "middle",
                              rowKey: "id",
                              columns: _vm.columns,
                              customRow: _vm.customRow,
                              dataSource: _vm.leftDataSource,
                              pagination: false,
                              loading: _vm.psdLoading,
                              scroll: {
                                x: "max-content",
                                y: "calc(100vh - 460px)",
                              },
                              rowSelection: {
                                selectedRowKeys: _vm.selectedPsdRowKeys,
                                onChange: _vm.onPsdSelectChange,
                                columnWidth: 45,
                              },
                            },
                            on: { change: _vm.handlePsdTableChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cc",
                                  fn: function (text, record) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            record.width + "×" + record.height
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                                {
                                  key: "fileLength",
                                  fn: function (text, record) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            (
                                              record.fileLength /
                                              1024 /
                                              1024
                                            ).toFixed(2) + "M"
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2157060691
                            ),
                          })
                        : _vm.leftShow
                        ? _c(
                            "div",
                            { staticClass: "prog-div" },
                            [
                              _c("Progress", {
                                attrs: {
                                  width: 300,
                                  progress: _vm.leftNum,
                                  strokeWidth: 10,
                                  showInfo: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.viewType == "widthView" && !_vm.leftShow
                        ? _c(
                            "a-spin",
                            { attrs: { spinning: _vm.psdLoading } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.leftDataSource.length &&
                                        !_vm.leftShow,
                                      expression:
                                        "!leftDataSource.length && !leftShow",
                                    },
                                  ],
                                  staticClass: "prog-div",
                                },
                                [
                                  _c("a-empty", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: { image: _vm.simpleImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "draggable",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.leftDataSource.length &&
                                        !_vm.leftShow,
                                      expression:
                                        "leftDataSource.length && !leftShow",
                                    },
                                  ],
                                  staticClass: "img-list",
                                  attrs: {
                                    dragClass: "drag-img",
                                    ghostClass: "ghost",
                                    animation: 300,
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: function ($event) {
                                      _vm.drag = false
                                    },
                                    sort: function ($event) {
                                      return _vm.dragEnd(_vm.leftDataSource)
                                    },
                                  },
                                  model: {
                                    value: _vm.leftDataSource,
                                    callback: function ($$v) {
                                      _vm.leftDataSource = $$v
                                    },
                                    expression: "leftDataSource",
                                  },
                                },
                                _vm._l(
                                  _vm.leftDataSource,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "img-div",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectLeftImg(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          class: item.checked
                                            ? "select-img"
                                            : "",
                                          staticStyle: { width: "140px" },
                                          attrs: {
                                            src: item.filePath,
                                            alt: "",
                                          },
                                        }),
                                        _c("div", { staticClass: "img-name" }, [
                                          _vm._v(_vm._s(item.fileName)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm.viewType == "heightView" && !_vm.leftShow
                        ? _c(
                            "a-spin",
                            { attrs: { spinning: _vm.psdLoading } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.leftDataSource.length,
                                      expression: "!leftDataSource.length",
                                    },
                                  ],
                                  staticClass: "prog-div",
                                },
                                [
                                  _c("a-empty", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: { image: _vm.simpleImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "draggable",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.leftDataSource.length,
                                      expression: "leftDataSource.length",
                                    },
                                  ],
                                  staticClass: "img-list",
                                  attrs: {
                                    dragClass: "drag-img",
                                    ghostClass: "ghost",
                                    animation: 300,
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: function ($event) {
                                      _vm.drag = false
                                    },
                                    sort: function ($event) {
                                      return _vm.dragEnd(_vm.leftDataSource)
                                    },
                                  },
                                  model: {
                                    value: _vm.leftDataSource,
                                    callback: function ($$v) {
                                      _vm.leftDataSource = $$v
                                    },
                                    expression: "leftDataSource",
                                  },
                                },
                                _vm._l(
                                  _vm.leftDataSource,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "img-div-height",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectLeftImg(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          class: item.checked
                                            ? "select-img"
                                            : "",
                                          staticStyle: { height: "350px" },
                                          attrs: {
                                            src: item.filePath,
                                            alt: "",
                                          },
                                        }),
                                        _c("div", { staticClass: "img-name" }, [
                                          _vm._v(_vm._s(item.fileName)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.viewType == "list" && !_vm.leftShow
                        ? _c("div", { staticClass: "select-num" }, [
                            _vm._v(
                              "\n            已选择：" +
                                _vm._s(_vm.selectedPsdRowKeys.length) +
                                "项，共" +
                                _vm._s(_vm.leftDataSource.length) +
                                "项\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "a-col",
                { staticClass: "right-content", attrs: { span: 12 } },
                [
                  _c(
                    "div",
                    { staticClass: "left-content-button-list" },
                    [
                      _c("defaultSizeUpload", {
                        attrs: {
                          btnText: "上传JPG",
                          upType: "JPG",
                          taskDetail: _vm.taskDetail,
                          btnType: "original",
                          sizeList: _vm.sizeList,
                          showFileList: _vm.rightDataSource,
                          loadData: _vm.getRightTableData,
                          saveUrl: "/kt/translate/uploadOriginalManuscript",
                        },
                        on: {
                          startProgress: _vm.startProgress,
                          changeProgress: _vm.changeProgress,
                          changeTransProgress: _vm.changeTransProgress,
                          endProgress: _vm.endProgress,
                        },
                      }),
                      _c("defaultSizeReplace", {
                        attrs: {
                          btnText: "替换",
                          upType: "JPG",
                          taskDetail: _vm.taskDetail,
                          btnType: "original",
                          sizeList: _vm.sizeList,
                          loadData: _vm.getRightTableData,
                          showFileList: _vm.rightDataSource,
                          selectKeys: _vm.selectedJpgRowKeys,
                          saveUrl: "/original/manuscript/replace",
                        },
                        on: {
                          startProgress: _vm.startProgress,
                          changeProgress: _vm.changeProgress,
                          changeTransProgress: _vm.changeTransProgress,
                          endProgress: _vm.endProgress,
                        },
                      }),
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            disabled: !_vm.selectedJpgRowKeys.length,
                            loading: _vm.btnLoading,
                            icon: "delete",
                            type: "danger",
                          },
                          on: { click: _vm.deleteJpgImg },
                        },
                        [_vm._v("\n            删除\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "left-content-list" },
                    [
                      _vm.viewType == "list" && !_vm.rightShow
                        ? _c("a-table", {
                            class: _vm.rightDataSource.length
                              ? "have-data"
                              : "",
                            attrs: {
                              size: "middle",
                              rowKey: "id",
                              columns: _vm.columnsRight,
                              dataSource: _vm.rightDataSource,
                              pagination: false,
                              loading: _vm.jpgLoading,
                              customRow: _vm.customRowJpg,
                              scroll: {
                                x: "max-content",
                                y: "calc(100vh - 460px)",
                              },
                              rowSelection: {
                                selectedRowKeys: _vm.selectedJpgRowKeys,
                                onChange: _vm.onJpgSelectChange,
                                columnWidth: 45,
                              },
                            },
                            on: { change: _vm.handleJpgTableChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cc",
                                  fn: function (text, record) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            record.width + "×" + record.height
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                                {
                                  key: "fileLength",
                                  fn: function (text, record) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            (
                                              record.fileLength /
                                              1024 /
                                              1024
                                            ).toFixed(2) + "M"
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2157060691
                            ),
                          })
                        : _vm.rightShow
                        ? _c(
                            "div",
                            { staticClass: "prog-div" },
                            [
                              _c("Progress", {
                                attrs: {
                                  width: 300,
                                  progress: _vm.rightNum,
                                  strokeWidth: 10,
                                  showInfo: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.viewType == "widthView" && !_vm.rightShow
                        ? _c(
                            "a-spin",
                            { attrs: { spinning: _vm.jpgLoading } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.rightDataSource.length,
                                      expression: "!rightDataSource.length",
                                    },
                                  ],
                                  staticClass: "prog-div",
                                },
                                [
                                  _c("a-empty", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: { image: _vm.simpleImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "draggable",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.rightDataSource.length,
                                      expression: "rightDataSource.length",
                                    },
                                  ],
                                  staticClass: "img-list",
                                  attrs: {
                                    dragClass: "drag-img",
                                    ghostClass: "ghost",
                                    animation: 300,
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: function ($event) {
                                      _vm.drag = false
                                    },
                                    sort: function ($event) {
                                      return _vm.dragEnd(_vm.rightDataSource)
                                    },
                                  },
                                  model: {
                                    value: _vm.rightDataSource,
                                    callback: function ($$v) {
                                      _vm.rightDataSource = $$v
                                    },
                                    expression: "rightDataSource",
                                  },
                                },
                                _vm._l(
                                  _vm.rightDataSource,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "img-div",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRightImg(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          class: item.checked
                                            ? "select-img"
                                            : "",
                                          staticStyle: {
                                            width: "100%",
                                            height: "max-content",
                                          },
                                          attrs: {
                                            src: item.filePath,
                                            alt: "",
                                          },
                                          on: {
                                            load: function ($event) {
                                              return _vm.imgOnError(index)
                                            },
                                          },
                                        }),
                                        _c("div", { staticClass: "img-name" }, [
                                          _vm._v(_vm._s(item.fileName)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm.viewType == "heightView" && !_vm.rightShow
                        ? _c(
                            "a-spin",
                            { attrs: { spinning: _vm.jpgLoading } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.rightDataSource.length,
                                      expression: "!rightDataSource.length",
                                    },
                                  ],
                                  staticClass: "prog-div",
                                },
                                [
                                  _c("a-empty", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: { image: _vm.simpleImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "draggable",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.rightDataSource.length,
                                      expression: "rightDataSource.length",
                                    },
                                  ],
                                  staticClass: "img-list",
                                  attrs: {
                                    dragClass: "drag-img",
                                    ghostClass: "ghost",
                                    animation: 300,
                                  },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: function ($event) {
                                      _vm.drag = false
                                    },
                                    sort: function ($event) {
                                      return _vm.dragEnd(_vm.rightDataSource)
                                    },
                                  },
                                  model: {
                                    value: _vm.rightDataSource,
                                    callback: function ($$v) {
                                      _vm.rightDataSource = $$v
                                    },
                                    expression: "rightDataSource",
                                  },
                                },
                                _vm._l(
                                  _vm.rightDataSource,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "img-div-height",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectRightImg(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          class: item.checked
                                            ? "select-img"
                                            : "",
                                          staticStyle: { height: "350px" },
                                          attrs: {
                                            src: item.filePath,
                                            alt: "",
                                          },
                                          on: {
                                            load: function ($event) {
                                              return _vm.imgOnError(index)
                                            },
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "img-name",
                                            staticStyle: { width: "100%" },
                                          },
                                          [_vm._v(_vm._s(item.fileName))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.viewType == "list" && !_vm.rightShow
                        ? _c("div", { staticClass: "select-num" }, [
                            _vm._v(
                              "\n            已选择：" +
                                _vm._s(_vm.selectedJpgRowKeys.length) +
                                "项，共" +
                                _vm._s(_vm.rightDataSource.length) +
                                "项\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.jointShow,
              expression: "jointShow",
            },
          ],
          staticClass: "joint-show",
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.selectionPsdRows, function (item) {
                return _c("img", {
                  key: item.id,
                  ref: "img-" + item.id,
                  refInFor: true,
                  style: { width: item.width + "px" },
                  attrs: { src: item.filePath, alt: "" },
                })
              }),
              _vm._l(_vm.selectionJpgRows, function (item) {
                return _c("img", {
                  key: item.id,
                  ref: "img-" + item.id,
                  refInFor: true,
                  style: { width: item.width + "px" },
                  attrs: { src: item.filePath, alt: "" },
                })
              }),
            ],
            2
          ),
        ]
      ),
      _c("font-modal", { ref: "fontModal", on: { saveFont: _vm.saveFont } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }