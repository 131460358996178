var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-upload",
    {
      attrs: {
        name: "file",
        showUploadList: false,
        accept: ".jpg,.psd,.zip",
        multiple: false,
        "before-upload": _vm.beforeUpload,
        "custom-request": _vm.handleRequest,
        disabled: !_vm.selectKeys.length || _vm.selectKeys.length > 1,
      },
    },
    [
      !_vm.isTable
        ? _c(
            "a-button",
            {
              staticClass: "mr-3",
              attrs: {
                disabled: !_vm.selectKeys.length || _vm.selectKeys.length > 1,
                loading: _vm.loading,
                icon: "upload",
              },
            },
            [_vm._v(_vm._s(_vm.btnText) + "\n  ")]
          )
        : _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c("a-icon", {
                staticStyle: { "font-size": "24px" },
                attrs: { slot: "indicator", type: "loading", spin: "" },
                slot: "indicator",
              }),
              _c("a", [_vm._v(_vm._s(_vm.btnText))]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }